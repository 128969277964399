import Header from './Templates/header';

function App() {
  return (
    <>
      <Header/>
    </>
  );
}

export default App;
