import { BrowserRouter, Route, Routes, Link, HashRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";

import "../css/header.css";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import About from "../pages/About";

import $ from 'jquery';



export default function Header() {

    const toggle = () => {
        ($("#toggle").hasClass("tgl_hidden"))?(()=>{
            $("#toggle").show();
            $("#toggle").removeClass("tgl_hidden");
            $("#toggle").addClass("tgl_visible");
        })():(()=>{
            $("#toggle").hide();
            $("#toggle").addClass("tgl_hidden");
            $("#toggle").removeClass("tgl_visible");
        })();
        
    }

    return (
        <navbar>
            <HashRouter>
                <nav className="myNav">
                    <Link to="/"><span style={{ fontFamily: "cursive", fontWeight: "bold", color: "blue", fontSize: "30px", padding: "35px" }}>a2Z loan</span></Link>
                    <Link to="About" style={{ float: "right", paddingTop: "12px" }}>ABOUT</Link>
                    <Link to="/Contact" style={{ float: "right", paddingTop: "12px", marginRight: "30px" }}>CONTACT</Link>
                    <Link to="https://sg2plzcpnl504599.prod.sin2.secureserver.net:2096/cpsess6877893046/3rdparty/roundcube/index.php?_task=mail&_mbox=INBOX" style={{ float: "right", paddingTop: "12px", marginRight: "30px" }} target="_blank">EMAIL</Link>
                </nav>
                <nav className="myNav-mob">
                    <Link to="/"><span style={{ fontFamily: "cursive", fontWeight: "bold", color: "blue", fontSize: "30px", }}>a2Z loan</span></Link>
                    <FontAwesomeIcon icon={faBars} style={{ float: "right", paddingTop: "12px" }} onClick={toggle} />
                </nav>
                <div id="toggle" className="tgl_hidden" style={{ display: "none" }}>
                    <ul>
                        <li><Link to="/About" style={{}}>ABOUT</Link></li>
                        <li><Link to="/Contact" style={{}}>CONTACT</Link></li>
                        <li><Link to="https://sg2plzcpnl504599.prod.sin2.secureserver.net:2096/cpsess6877893046/3rdparty/roundcube/index.php?_task=mail&_mbox=INBOX" target="_blank" style={{}}>EMAIL</Link></li>
                    </ul>
                </div>
                <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/About" element={<About />}></Route>
                    <Route path="/Contact" element={<Contact />}></Route>
                </Routes>
            </HashRouter>

        </navbar>
    )
}